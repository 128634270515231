import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import logo from "../../../assets/images/logo/form.svg";

class NoMatch extends Component {
	render() {

		return (
			<div className="not-found-container">
				<img alt="form-logo" src={logo}/>
				<h2>Oeps we couldn't find {this.props.location.pathname}</h2>
				<NavLink exact to="/">Return to demo</NavLink>
			</div>
		);
	}
}
export default NoMatch;
