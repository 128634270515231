import React, {Component} from 'react';
import {Route} from 'react-router-dom';
import './App.scss';
import {Switch} from 'react-router';
// import Home from "./routes/Home";
import Header from "./components/header/Header";
import {UnityContent} from "react-unity-webgl";
import GameEngine from "./components/game-engine/GameEngine";
import NoMatch from "./components/global/404/NoMatch";
import {Redirect} from 'react-router-dom'

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fullScreen: false,
            isLoading: true
        };

        this.unityContent = new UnityContent(
            "Unity/Build/AH_GeneratedBuild.exe.json",
            "Unity/Build/UnityLoader.js"
        );

        this.unityContent.on("loaded", () => {
            this.setState({
                isLoading: false
            });
        });

    }

    render() {
        const {isLoading} = this.state;
        return (
            <div className="App">
                <Switch>
                    <Redirect exact from='/' to='/interior-demo'/>
                    <Route exact to="/interior-demo"
                           render={(props) =>
                               <div ref={this.element} >
                                   <Header/>
                                   <GameEngine {...props} isLoading={isLoading} unityContent={this.unityContent}/>
                               </div>
                           }/>
                    <Route component={NoMatch}/>
                </Switch>
            </div>
        )
    }
}

export default App
