import React from 'react';
import logo from "../../assets/images/logo/form.svg";

function Header(props) {
    return (
        <div className="header-container">
            <div className="header-logo">
                <img alt="form-logo" src={logo}/>
                <p>Interior Style Switching Demo</p>
            </div>
        </div>
    );
}

export default Header;