import React, {useRef} from 'react';
import Unity from "react-unity-webgl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes, faExpand} from "@fortawesome/free-solid-svg-icons";
import useFullscreenStatus from "../hooks/useFullscreenStatus";
import Button from "../global/button/Button";
import {mobileAndTabletcheck} from "../global/functions/IsMobile";
// import { css } from "@emotion/core";
import PropagateLoader from "react-spinners/PropagateLoader";

function GameEngine(props) {

    const refContainer = useRef(null);
    let isFullscreen, setIsFullscreen;
    let errorMessage;

    try {
        [isFullscreen, setIsFullscreen] = useFullscreenStatus(refContainer);
    } catch (e) {
        errorMessage = "Fullscreen not supported";
        isFullscreen = false;
        setIsFullscreen = undefined;
    }

    const closeFullScreen = () => document.exitFullscreen();
    const {unityContent, isLoading} = props;

    return (
        <React.Fragment>
            {mobileAndTabletcheck() ?
                (
                    <h2 className="computer-only"> This application only works on desktop browsers</h2>
                )
                :
                (
                    <React.Fragment>
                        <div ref={refContainer} id="game-engine" className={`game-engine-wrapper`}>
                            <Unity height="100%" width="100%" className="game-engine" unityContent={unityContent}/>
                            <div onClick={closeFullScreen} className={`close-full-screen ${isFullscreen && "active"}`}>
                                <FontAwesomeIcon icon={faTimes}/>
                            </div>

                            <div className={`game-engine-loader ${isLoading && "active"}`}>
                                <PropagateLoader
                                    size={20}
                                    color={"white"}
                                    loading={isLoading}
                                />
                            </div>
                        </div>
                        <div className="game-engine-button-wrapper">
                            <Button onClick={setIsFullscreen} icon={<FontAwesomeIcon icon={faExpand}/>}
                                    className="game-engine-button">Set fullscreen</Button>
                            {errorMessage ? alert("Fullscreen not supported by this browser") : null}
                        </div>
                    </React.Fragment>
                )
            }
        </React.Fragment>
    );
}

export default GameEngine;