import React from 'react';

const Button = (props) => {

    const { onClick, icon} = props;
    return (
            <div onClick={onClick}  className="button">
                {icon}
            </div>
    );
};

export default Button;